<template>
	<table class="tw-w-full">
		<thead>
			<tr class="tw-bg-blue-400 tw-text-white">
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.transactionDate") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.shopName") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.bookingNumber") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.deliveryNumber") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.driverCode") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.driverName") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.sex") }}
				</th>
				<!-- <th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
                    {{ $t("driverClearCash.contactNo") }}
                </th> -->
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.deliveryTime") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.receiverLocation") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.extraFee") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.deliveryFee") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.totalFee") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.codAmount") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.amountCollected") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.taxiFee") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.amountTobeClear") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.paymentMethod") }}
				</th>
				<th class="tw-px-4 tw-py-1 tw-whitespace-nowrap tw-border tw-text-right">
					{{ $t("driverClearCash.margin") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.remark") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.isClearCash") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.clearDate") }}
				</th>
				<th class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border">
					{{ $t("driverClearCash.clearBy") }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(r, index) in record" :key="index">
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.transaction_date }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.shop_name }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.booking_number }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.delivery_number }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.driver_code }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.driver_name }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.sex }}
				</td>
				<!-- <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                    {{ r.contact_no }}
                </td> -->
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					<p class="tw-mb-0 tw-space-x-2">
						<span class="tw-text-blue-400 tw-font-semibold">{{
							r.delivery_start_time
						}}</span>
						<i class="fas fa-arrow-right tw-text-red-600"></i>
						<span class="tw-text-yellow-600 tw-font-semibold">{{
							r.delivery_finish_time
						}}</span>
					</p>
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.receiver_location }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<ts-accounting-format :value="r.extra_fee" />
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<ts-accounting-format :value="r.delivery_fee" />
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<ts-accounting-format :value="r.total_fee" />
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<ts-accounting-format :value="r.cod_amount" />
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<div class="tw-flex tw-space-x-1">
						<div class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1">
							<span> $ </span>
							<span>
								{{
									$formatNumber(r.amount_collected_usd, 2)
								}}</span>
						</div>
						<div class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1">
							<span> KHR </span>
							<span>
								{{
									$formatNumber(r.amount_collected_khr, 0)
								}}</span>
						</div>
					</div>
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<ts-accounting-format currency="KHR" :digit="0" :value="r.taxi_fee" />
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<div class="tw-flex tw-space-x-1">
						<div class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1">
							<span> $ </span>
							<span>
								{{
									$formatNumber(r.amount_tobe_cleared_usd, 2)
								}}</span>
						</div>
						<div class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1">
							<span> KHR </span>
							<span>
								{{
									$formatNumber(r.amount_tobe_cleared_khr, 0)
								}}</span>
						</div>
					</div>
				</td>
				<td class="tw-text-center tw-px-2 tw-py-1 tw-border">
					{{ r.payment_method }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right">
					<ts-accounting-format currency="KHR" :digit="0"
						:value="(r.amount_tobe_collected_khr - r.amount_collected_khr)" />
				</td>
				<td class="tw-text-center tw-px-2 tw-py-1 tw-border">
					{{ r.remark }}
				</td>
				<td class="tw-text-center tw-px-2 tw-py-1 tw-border">
					<a-badge v-if="r.is_driver_clear_cash" count="Yes" :number-style="{
						backgroundColor: '#52c41a'
					}" />
					<a-badge v-else count="No" />
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.driver_clear_cash_date }}
				</td>
				<td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
					{{ r.driver_clear_cash_by }}
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: "tableDetail",
	props: ["record"]
};
</script>
