<template>
    <div>
        <div class="tw-flex tw-justify-between tw-items-center tw-p-4">
            <div class="tw-flex tw-items-center tw-space-x-3">
                <a-range-picker
                    v-model="dateRange"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    valueFormat="DD-MM-YYYY"
                    style="width: 200px"
                    @change="fetchData()"
                />
            </div>
            <div class="tw-space-x-3">
                <a-tooltip :title="$t('driverClearCash.search')">
                    <a-input-search
                        v-model="search"
                        :placeholder="$t('driverClearCash.search')"
                        style="width: 200px"
												@search="fetchData()"
                    />
                </a-tooltip>
            </div>
        </div>
        <div class="tw-overflow-x-scroll">
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
                :expandable="true"
            >
                <template v-slot="{ record }">
                    <td class="tw-whitespace-nowrap">
                        {{ record.cleared_date }}
                    </td>
                    <td class="tw-whitespace-nowrap">
                        {{ record.driver_code }}
                    </td>
                    <td class="tw-whitespace-nowrap">
                        {{ record.driver_name }}
                    </td>
                    <td class="tw-whitespace-nowrap">{{ record.sex }}</td>
                    <td class="tw-whitespace-nowrap">
                        {{ record.contact_no }}
                    </td>
                    <td class="tw-whitespace-nowrap">
                        {{ record.voucher_no }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-text-right">
                        <ts-accounting-format
                            :value="record.amount_tobe_collected"
                        />
                    </td>
                    <td class="tw-whitespace-nowrap tw-text-right">
                        <div class="tw-flex tw-space-x-1">
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span> $ </span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_collected_usd,
                                            2
                                        )
                                    }}</span
                                >
                            </div>
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span>៛</span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_collected_khr,
                                            0
                                        )
                                    }}</span
                                >
                            </div>
                        </div>
                    </td>
                    <td class="tw-whitespace-nowrap tw-text-right">
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="record.taxi_fee"
                        />
                    </td>
                    <td class="tw-whitespace-nowrap tw-text-right">
                        <div class="tw-flex tw-space-x-1">
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span> $ </span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_tobe_cleared_usd,
                                            2
                                        )
                                    }}</span
                                >
                            </div>
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span>៛</span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_tobe_cleared_khr,
                                            0
                                        )
                                    }}</span
                                >
                            </div>
                        </div>
                    </td>
                    <td class="tw-whitespace-nowrap">{{ record.remark }}</td>
                    <td class="tw-whitespace-nowrap">
                        {{ record.clear_cash_by }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-text-center">
                        <a
                            href="#"
                            class="tw-text-red-500"
                            @click.prevent="previewPdf(record)"
                        >
                            <i class="fas fa-file-pdf"></i>
                        </a>
                    </td>
                </template>
                <template v-slot:expand="{ record }">
                    <Details :record="record.details" />
                </template>
            </ts-table>
        </div>
        <div class="d-flex justify-content-end tw-p-4">
            <ts-pagination
                v-model="pagination"
                @navigate="fetchData"
            ></ts-pagination>
        </div>

        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="waiting" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Details from "./detail.vue";

export default {
    name: "driverClearCash",
    components: {
        Details
    },
    data() {
        return {
            waiting: false,
            preview_pdf: false,
            src: "",
            loading: false
        };
    },
    computed: {
        ...mapState("finance/driverClearCash", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.finance.driverClearCash.search;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverClearCash/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "finance/driverClearCash/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("driverClearCash.transactionDate"),
                    style: "width:30px",
                    sortKey: "cleared_date"
                },
                {
                    name: this.$t("driverClearCash.driverCode"),
                    sortKey: "driver_code"
                },
                {
                    name: this.$t("driverClearCash.driverName"),
                    sortKey: "driver_name"
                },
                { name: this.$t("driverClearCash.sex"), sortKey: "sex" },
                {
                    name: this.$t("driverClearCash.contactNo"),
                    sortKey: "contact_no"
                },
                {
                    name: this.$t("driverClearCash.voucherNo"),
                    sortKey: "voucher_no"
                },
                { name: this.$t("driverClearCash.amountToBeCollected") },
                { name: this.$t("driverClearCash.amountCollected") },
                { name: this.$t("driverClearCash.taxiFee") },
                { name: this.$t("driverClearCash.amountTobeClear") },
                { name: this.$t("driverClearCash.remark") },
                { name: this.$t("driverClearCash.clearBy") },
                { name: this.$t("actions") }
            ];
        },
        dateRange: {
            get() {
                return this.$store.state.finance.driverClearCash.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/driverClearCash/SET_DATE_RANGE",
                    newValue
                );
            }
        }
    },
    methods: {
        ...mapActions("finance/driverClearCash", ["toPdf"]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("finance/driverClearCash/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        previewPdf(record) {
            this.preview_pdf = true;
            this.waiting = true;
            this.toPdf(record.driver_clear_cash_id)
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        }
    },
};
</script>
