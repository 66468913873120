<template>
    <div>
        <ts-page-title
            :title="$t('driverClearCash.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('driverClearCash.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <a-button
                        type="primary"
                        @click="addNew"
                        v-if="$can('create-driver-clear-cashes')"
                    >
                        {{ $t("addNew") }}
                    </a-button>
                </div>
            </ts-panel-wrapper>
            <a-tabs :default-active-key="active_tab" @change="onTabChange">
                <a-tab-pane :key="1" :tab="$t('driverClearCash.allData')"  force-render> 
                    <all-data ref="all" />
                </a-tab-pane>
                <a-tab-pane :key="2" :tab="$t('driverClearCash.clearanceHistory')" force-render>
                    <ClearHistory ref="history"/>
                </a-tab-pane>
            </a-tabs>
        </ts-panel>
    </div>
</template>

<script>
import ClearHistory from './components/clear-history.vue'
import AllData from './components/all-data.vue'
export default {
    name: "driverClearCash",
		components: {
			ClearHistory,
			AllData
		},
    data() {
        return {
            active_tab: 1
        };
    },
    methods: {
        addNew() {
            this.$router.push({ name: "driver-clear-cash-create" });
        },
				onTabChange(index){
					if(index == 1)
					{
						this.$refs.history.fetchData();
					}
					if(index == 2)
					{
						this.$refs.all.fetchData();
					}
				}
    },
		beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.all.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("finance/driverClearCash/RESET_STATE");
        next();
    }
};
</script>
